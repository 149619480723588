<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['customer:person:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :statusOptions="dict.type.sys_enable_disable"
        :listGroup="listGroup"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="kefu_id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="kefu_avatar" slot-scope="text, record">
          <a-avatar size="small" :src="'/customerserviceurl'+record.kefu_avatar"/>
        </span>

         <span slot="kefu_status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.kefu_status"/>
        </span>


        <span slot="online_status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no_number']" :value="record.online_status"/>
        </span>
        <span slot="operation" slot-scope="text, record">
<!--           <a @click="handleOpenCustomerService(record)" v-hasPermi="['customer:person:work']">-->
<!--             客服工作台-->
<!--          </a>-->
           <a-divider type="vertical" v-hasPermi="['customer:person:edit']" />
          <a @click="chatWork(record)" v-hasPermi="['customer:person:edit']">
            <a-icon type="edit" /> 客服工作台
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['customer:person:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record)" v-hasPermi="['customer:person:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['customer:person:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['customer:person:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {listPerson, delPerson } from '@/api/customerservice/person'
import {listGroup } from '@/api/customerservice/group'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import Cookies from 'js-cookie';
export default {
  name: 'Cancel',
  components: {
    CreateForm,Cookies
  },
  mixins: [tableMixin],
  dicts: ['sys_enable_disable','sys_yes_no_number'],
  data () {
    return {
      list: [],
      listGroup:[],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      // 查询参数
      queryParam: {
        mobile: null,
        reason: null,
        failReason: null,
        examineName: null,
        examineTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '客服标识',
          dataIndex: 'kefu_code',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '客服名称',
          dataIndex: 'kefu_name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '客服头像',
          dataIndex: 'kefu_avatar',
          scopedSlots: { customRender: 'kefu_avatar' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '所属分组',
        //   dataIndex: 'group_name',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '服务最大人数',
        //   dataIndex: 'max_service_num',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '客服状态',
        //   dataIndex: 'kefu_status',
        //   scopedSlots: { customRender: 'kefu_status' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '在线状态',
        //   dataIndex: 'online_status',
        //   scopedSlots: { customRender: 'online_status' },
        //   ellipsis: true,
        //   align: 'center'
        // } ,{
        //   title: '最后登录时间',
        //   dataIndex: 'last_login_time',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  mounted() {
  },
  watch: {
  },
  methods: {

    chatWork(res){

      //获取token
      // let token=Cookies.get('Authorization');

      // app.use((req, res, next) => {
      //   const authorizationHeader = req.headers.authorization;
      //   console.log('Authorization header:', authorizationHeader);
      //   next();
      // });
      window.open('http://im.tjzmkj.fun/?id='+res.kefu_id+ "&name="+res.kefu_name,"_blank");
    },

    /** 进入客服工作台 */
    handleOpenCustomerService(row){
      window.open(process.env.VUE_APP_BASE_API+"/customer/person/open-customer-work/"+row.kefu_code,"_blank")
    },
    /** 查询客服分组列表 */
    // getListGroup () {
    //   listGroup().then(response => {
    //     this.listGroup = response.data
    //   })
    // },

    /** 查询用户注销列表 */
    getList () {
      this.loading = true
      this.list=[{
        kefu_code: "88888888",
        kefu_id: '88888888',
        kefu_name: "VIP管家",
        kefu_avatar: "/static/common/images/kefu/1.png",
        group_name: "分组1",
        max_service_num: 10,
        kefu_status: 1,
        online_status: 1,
        last_login_time: "2020-09-01 12:00:00"
      },{
        kefu_code: "112233",
        kefu_id: '112233',
        kefu_name: "平台客服",
        kefu_avatar: "/static/common/images/kefu/1.png",
        group_name: "分组1",
        max_service_num: 10,
        kefu_status: 1,
        online_status: 1,
        last_login_time: "2020-09-01 12:00:00"
      }]

      // listPerson().then(response => {
      //   this.list = response.data
        this.loading = false
      // })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        mobile: undefined,
        reason: undefined,
        status: undefined,
        failReason: undefined,
        examineName: undefined,
        examineTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },

    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const name = row.kefu_name
      this.$confirm({
        title: '确认删除所选中数据?',
        content:  name,
        onOk () {
          let data = [{"kefu_id":row.kefu_id}]
          return delPerson(data)
            .then((response) => {
              if (response) {
                that.getList()
                that.$message.success(
                  '删除成功',
                  3
                )
              }
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    }
  }
}
</script>
